export const PostTypeEnum = {
    GENERAL: "general",
    QUESTION: "question",
    TIP: "tip",
    REVIEW: "review",
    NEWS: "news",
    TCGERS: "tcgers", // admin
    COMPETITIVE: "competitive",
    PULLS: "pulls",
    GRADING: "grading",
    EVENTS: "events",
};

export const PostTypeEnumValues = {
    [PostTypeEnum.GENERAL]: "Algemeen",
    [PostTypeEnum.QUESTION]: "Vraag",
    [PostTypeEnum.TIP]: "Tip",
    [PostTypeEnum.REVIEW]: "Review",
    [PostTypeEnum.NEWS]: "Nieuws",
    [PostTypeEnum.TCGERS]: "TCGers",
    [PostTypeEnum.COMPETITIVE]: "Competitie",
    [PostTypeEnum.PULLS]: "Pulls",
    [PostTypeEnum.GRADING]: "Grading",
    [PostTypeEnum.EVENTS]: "Evenementen",
};
